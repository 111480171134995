import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CountryGuard } from './guards/country.guard';
import { LoginGuard } from './guards/login.guard';
import { HomePage } from './pages/home/home.page';
import { ProxPage } from './pages/prox/home.page';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('src/app/pages/entry/welcome/welcome.module').then((m) => m.WelcomePageModule),
    canActivate: [LoginGuard],
  },
  // {
  //   path: 'verified-email',
  //   loadChildren: () => import('src/app/pages/entry/verified-email/welcome.module').then((m) => m.WelcomePageModule),
  //   canActivate: [LoginGuard],

  // },
  { path: 'home', component: HomePage, canActivate: [CountryGuard] },

  { path: 'prox/home', component: ProxPage },

  {
    path: 'login',
    loadChildren: () => import('src/app/pages/entry/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },

  // {
  //   path: 'check-payment',
  //   loadChildren: () => import('src/app/pages/check-payment/check-payment.module').then((m) => m.CheckPaymentPageModule),
  // },

  {
    path: 'recover',
    loadChildren: () => import('src/app/pages/entry/recover/recover.module').then((m) => m.RecoverPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'register',
    loadChildren: () => import('src/app/pages/entry/register/register.module').then((m) => m.RegisterPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'account-validation',
    loadChildren: () =>
      import('./pages/entry/account-validation/account-validation.module').then((m) => m.AccountValidationPageModule),
  },
  {
    path: 'claim-benefit',
    loadChildren: () =>
      import('./pages/entry/claim-benefit/claim-benefit.module').then((m) => m.ClaimBenefitPageModule),
  },

  {
    path: 'ar',
    loadChildren: () => import('./countries/ar/argentina.module').then((m) => m.ArgentinaModule),
    canActivate: [AuthGuard, CountryGuard],
    data: { country: 'ar' },
  },
  {
    path: 'pe',
    loadChildren: () => import('./countries/pe/peru.module').then((m) => m.PeruModule),
    canActivate: [AuthGuard, CountryGuard],
    data: { country: 'pe' },
  },
  {
    path: 'uy',
    loadChildren: () => import('./countries/uy/uruguay.module').then((m) => m.UruguayModule),
    canActivate: [AuthGuard, CountryGuard],
    data: { country: 'uy' },
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}