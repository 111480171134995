import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OpenaiService {
  private apiUrl = 'https://api.openai.com/v1/chat/completions';
  private apiKey = 'sk-48_PTWNfPXKvSxP9eA91tDnfconcBdd561wGiD5-VVT3BlbkFJ9pKi1mht6EY_0EMsMRRD7W_UaT-CEHKwqxh7GBrokA';
  private httpClient: HttpClient;

  // Base de datos local de preguntas frecuentes
  private faqs = {
    'Que es el Monotributo': `
      El Monotributo es un régimen simplificado de pago de impuestos de bajo costo, orientado a favorecer a los trabajadores independientes que fue creado en 1998 mediante la Ley 24.977.
      Ahora bien, este consiste en abonar una cuota fija mensual que contiene un componente impositivo (Iva y Ganancias) y otro previsional (Aportes a la jubilación y Obra Social) unificados.
      Por otro lado, cuenta con categorías según la facturación anual, superficie de locales, energía eléctrica consumida y el monto de alquiler abonado del espacio de trabajo. Dependiendo a la que pertenecen, los Monotributistas no pueden superar un determinado máximo anual de ingresos para poder permanecer en el régimen.
      A continuación te dejamos un video explicativo de nuestro canal de Youtube que lo explica todo visualmente.
      ¿Cuáles son los beneficios del monotributo?
      Ahora bien, los beneficios que podemos mencionar de este régimen fiscal son:
      Permite a los contribuyentes pagar menos impuestos de los que deberían si estuvieran inscriptos en otro régimen fiscal
      Facilita el cumplimiento de las obligaciones tributarias al simplificar los trámites y reducir la cantidad de impuestos y aportes que deben ser pagados.
      Con una cuota fija mensual cubrís el pago de tus obligaciones impositivas y de seguridad social.
      Permite formalizar tu actividad, pudiendo emitir facturas y tener cobertura médica para vos y tu familia, así como también acceder a una jubilación.
      Existen diversos tipos (detallados a continuación)
      Tipos de Monotributo
      Existen diversos tipos de Monotributo. Podrás encontrar la explicación a cada uno de ellos en nuestra academia gratuita.
      Monotributo Nacional
      Monotributo Promovido
      Monotributo Social
      Monotributo Unificado
      Podés consultar más información en nuestra Academia o en la página de la Arca.
      Recordá que podés realizar toda la gestión de tus impuestos (inscripción, consulta de deuda y vencimientos, pagos, etc.) a través de nuestra APP.
      ¡Esperamos que esta información te haya sido útil! ¡Gracias por aprender con nosotros!
      Tributo Simple, Tu Contador en una APP. ¡Descargá la APP y viví la experiencia #TaxTech!
    `,
    '¿Que son los Ingresos Brutos?': `
      El sistema tributario en Argentina puede resultar muy engorroso, es por eso que hoy te aclaramos una duda muy usual: ¿Qué es el impuesto a Ingresos Brutos?
      El impuesto a los Ingresos Brutos es un tributo provincial por el cual se abona un porcentaje sobre los ingresos obtenidos del contribuyente por ejercer su actividad. Lo aplican todas las provincias argentinas y la Ciudad Autónoma de Buenos Aires.
      A continuación te dejamos un video de nuestro canal de Youtube que lo explica.

      ¿Cómo se calcula?
      El Impuesto a los Ingresos Brutos se obtiene aplicando un porcentaje sobre las ventas realizadas, su alícuota depende de la actividad y la provincia. Es decir, se rige por el Código Fiscal y las leyes impositivas de cada jurisdicción.
      ¿Al ser un tributo provincial, como me inscribo?
      Si la persona desarrolla su actividad solo en un distrito, debe inscribirse como contribuyente local.
      En cambio, en los casos en que las actividades abarcan a diferentes jurisdicciones, el contribuyente debe inscribirse en el convenio multilateral, es decir, un acuerdo entre provincias para organizar de forma más eficiente el pago de los impuestos.
      Por otro lado, en ciertas jurisdicciones los monotributistas cuentan con la posibilidad de adherirse al monotributo unificado, a través del cual pueden abonar el Monotributo e Ingresos Brutos en una sola cuota fija mensual.
      Recordá que podés realizar toda la gestión del impuesto a los ingresos brutos (inscripción, presentaciones mensuales, presentaciones anuales, consulta de deuda, vencimientos, pagos, etc.) a través de nuestra APP.
      ¡Esperamos que esta información te haya sido útil! ¡Gracias por aprender con nosotros!
    `,
    'Que tipo de facturas existen': `
      Una de las obligaciones como contribuyente es emitir facturas o comprobantes equivalentes por las ventas, prestaciones y locaciones de servicio realizadas. Las facturas son un documento que acredita que se ha realizado una transacción comercial entre dos partes, y que especifica la cantidad y el precio de los bienes o servicios vendidos. Además, las facturas también proporcionan información importante, como el IVA o impuesto sobre el valor añadido, que se aplica a la transacción. Es por eso que resulta de gran importancia que conozcas los distintos tipos de facturas que existen.
      Tipos de facturas disponibles
      Existen las facturas tipo “A”, “B”, “C”, “M”, “E” y “T”. A continuación te explicamos brevemente para qué situación se utiliza cada una de ellas.
      Factura A: de responsable inscripto a responsable inscripto o monotributista.
      Factura B: de responsable inscripto a consumidor final o exento en IVA.
      Factura C: de monotributista o exento en IVA a todo destinatario.
      Factura M: de responsable inscripto a responsable inscripto
      Factura E: de exportador a sujeto del exterior.
      Factura T: de servicio de alojamiento a turistas extranjeros.
      ¿Cómo emito las facturas?
      En cuanto a la emisión podés hacerlo, en primer lugar, mediante facturación electrónica desde la página de la AFIP o Tributo Simple. Asimismo, existen los controladores fiscales (utilizados más que nada en puntos de venta minoristas) y la factura manual que está permitida únicamente en situaciones particulares.
      ¿Qué datos necesito para poder emitir comprobantes?
      Para poder emitir cualquier tipo de factura, hay cierta información que es indispensable. Como lo son:
      CUIT del emisor
      Punto de Venta
      Tipo y número de comprobante
      Importe Neto
      Moneda y Cotización
      DNI / CUIT del receptor
      Código de Autorización CAE
      ¿Qué factura emite un Monotributista que trabaja para Argentina?
      Ahora bien, los monotributistas particularmente emiten comprobantes de tipo “C” para operaciones realizadas en Argentina. Las facturas tipo C son importantes porque permiten a los contribuyentes del Régimen Simplificado llevar un registro de sus transacciones comerciales y de sus ingresos, lo que puede ser útil a la hora de declarar sus impuestos. Además, las facturas tipo C también pueden ser utilizadas como pruebas en caso de disputas o conflictos con el comprador.
      ¿Qué factura emite un monotributista que exporta?
      Un monotributista que exporta sus servicios debe emitir una factura tipo “E” para facturar sus servicios a clientes extranjeros.
      ¿Querés aprender más sobre el monotributo? No te pierdas de nada en nuestra Academia
    `,
    'Que es la recategorización del Monotributo': `
      La recategorización es un procedimiento obligatorio que se realiza dos veces al año (Enero y Julio) donde las y los contribuyentes deben evaluar qué categoría del Monotributo le corresponde de acuerdo a su actividad durante los últimos 12 meses.
      ⚠️Todos los contribuyentes deben realizar el procedimiento⚠️
      Cuando no corresponda modificar la categoría, las personas deben confirmar su permanencia en la que corresponda. Si te inscribiste hace menos de 6 meses el trámite no tiene carácter obligatorio.
      ¿Cómo es la recategorización de monotributo 2024?
      La recategorización es la evaluación que deben realizar los monotributistas 2 veces al año para saber si continúan en la misma categoría, deben cambiarla, o bien excluirse del régimen.
      Para ello, deben contemplar, según el caso, sus parámetros de ingresos brutos acumulados, energía eléctrica consumida, alquileres devengados y/o superficie afectada en la actividad de los últimos 12 meses y cotejar a qué categoría corresponden según la tabla de categorías del monotributo vigente.
      La recategorización se hace del 1 al 20 de enero y julio. Este año, debido a la aprobación de la Ley Bases, la fecha límite de la recategorización se aplazó hasta el 2 de agosto, teniendo efecto desde el 01/08/2024 hasta el 31/01/2025.
      Algunos puntos a considerar..
      La facturación no debe superar el tope anual del regimen del monotributo siendo de $68.000.000 en la categoría K.
      Los depósitos bancarios que tengan relación con la actividad desarrollada no pueden ser iguales o superiores al máximo de la categoría mencionada en el punto anterior.
      No se puede poseer más de 3 actividades simultaneas o más de 3 unidades de explotación.
      El tope de los alquileres anuales es de $ 4.500.000. El local no puede superar los 200m2
      El total de las compras más los gastos de la actividad encuadrada en el Monotributo durante los 12 meses anteriores no pueden ser iguales o superiores a:
      80% del máximo del régimen simplificado para venta de productos.
      40% del máximo de ingreso para la prestación de servicios.
      No haber realizado importaciones de bienes o servicios en los últimos 12 meses.
      ¿Que es la recategorización de oficio?
      La recategorización de oficio procederá cuando AFIP verifique el Monotributista no se recategorizó o habiéndola realizado, la misma sea inexacta.
      Asimismo, cuando se verifique que se realizaron compras y gastos necesarios para el desarrollo de la actividad, se adquirieron bienes, se realizaron gastos de índole personal o se posean acreditaciones bancarias, por un valor que supere el importe de los ingresos brutos anuales máximos admitidos para la categoría en la cual está encuadrado.
      La nueva categoría a asignar será la que corresponda al importe de ingresos brutos anuales resultante de la sumatoria de las compras y gastos indicados más el 20% o el 30% de dicho valor, según se trate la actividad de locación, prestación de servicios y/o ejecución de obras, o de venta de cosas muebles, respectivamente.El contribuyente recategorizado de oficio podrá consultar los motivos, así como la liquidación de los montos adeudados más sus intereses accediendo al servicio “Monotributo – Recategorización de Oficio – (MOREO)”
      El Monotributista dispondrá de un plazo de 15 días contados desde la fecha de publicación de la recategorización en el Boletín Oficial o de la notificación en el domicilio fiscal electrónico para apelar la medida.
      De corresponder, a fines de no esperar una recategorización de oficio por parte de AFIP, es posible subir de categoría durante todo el año realizando el trámite desde la opción “Modificar datos del monotributo” dentro del Portal de Monotributo en la pagina web del Organismo.
      En caso de querer solicitar una baja de categoría, el aplicativo no permite hacerlo. En tal sentido, se deberá realizar una presentación digital justificando el motivo, como puede ser un error cometido a la hora de recategorizar, entre otros.
  `,
    'Colaboradores y el Monotributo': `
      A través del decreto 847/2024, publicado el 26 de septiembre en el Boletín Oficial, el Poder Ejecutivo reglamentó de manera parcial la reforma laboral establecida por la ley N° 27.742, denominada “Bases y Puntos de Partida para la Libertad de los Argentinos”. Entre otros cambios que impuso, se destaca la reglamentación de trabajo de colaboradores.
      Y es que, a partir de ahora, un trabajador independiente (ya sea monotributista o autónomo) puede tener hasta 3 colaboradores o trabajadores independientes. Lógicamente, estos trabajadores deben estar inscriptos en algún régimen de la AFIP (como por ejemplo, el monotributo) y presentar a este mismo organismo declaración jurada sobre la naturaleza independiente de la relación.
      Teniendo en cuenta que no existe relación de dependencia entre las partes, cualquiera de ellas podría darle fin al vínculo laboral sin la obligación de pagar preavisos o indemnizaciones. Además, esta relación laboral permite que el colaborador tenga la libertad de llevar a cabo múltiples actividades de manera simultánea si así lo desea.
      Esta disposición podría contribuir a regularizar muchas situaciones de empleo completamente informal, pero no se descuida la posibilidad de que se utilice esta nueva figura para disimular una relación de dependencia bajo la apariencia de colaboración. Es por eso que, para reducir este riesgo, se estableció que el trabajador independiente pueda únicamente contar con hasta 3 colaboradores y que no pueda despedir a un empleado para reemplazarlo por un colaborador.
      Por otro lado, los trabajadores independientes pueden acceder a un sistema de cobertura de accidentes personales.
      Características puntuales
      En conclusión, las características puntuales de esta relación laboral que parte de la reglamentación de trabajo con colaboradores incluyen que:
      El colaborador tiene la libertad total de aceptar contratos de colaboración, trabajo o de prestación de servicios de otros contratantes simultáneamente. Esta facultad no puede limitarse ni restringirse.
      Ambos integrantes del vínculo pueden finalizarlo en cualquier momento.
      El trabajador independiente no puede tener contrato vigente con más de 3 trabajadores independientes colaboradores a la vez.
      Este régimen no es aplicable si se estima que se ha reemplazado una relación laboral en dependencia por otra figura jurídica para aprovechar los beneficios del mismo.

  `,
    'Deuda en Afip': `
      Si querés saber si contás con alguna deuda en la AFIP Tributo Simple puede resultarte de gran ayuda. Esta plataforma facilita la experiencia de cumplir los deberes fiscales de los pequeños contribuyentes de manera simple y accesible a través de una APP con el respaldo de contadores matriculados en los consejos de ciencias económicas
      Descargá la APP, registrate y obtené tu informe fiscal GRATIS. Accedé a tu estado de cuenta y de cumplimiento.
      A continuación te dejamos un video tutorial sobre cómo registrarte en Tributo Simple para poder hacer lo mencionado anteriormente.


      Tu informe fical proporcionado por Tributo Simple consiste en:
      Datos Personales: Es decir, Nombre y Apellido / Cuit / Domicilio Fiscal, así como también el Real
      Datos Impositivos: Esto incluye Actividades / Monotributo (especificación de tu Categoría, los Parámetros del régimen y el Valor de la cuota)
      Facturación: Resumen de los últimos 12 meses. Limite de categoría actual vs lo facturado. Saldo remanente por facturar.
      Verificación del estado de deuda: Saldo deudor y Saldo Acreedor
      Controla tus impuestos desde la APP. Podés verificar si tenés deuda en la AFIP, revisar el estado de cuenta cuando quieras y cancelar el monto generando un VEP en un solo click. Para más información, no dudes en escribirnos!
      1166410871
      ayuda@tributosimple.com
      www.tributosimple.com
  `,
  };

  constructor(private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  // Método para enviar mensajes dinámicos
  sendMessage(message: string): Observable<any> {
    const matchedFAQ = this.getFAQResponse(message);

    if (matchedFAQ) {
      // Si hay una coincidencia en las preguntas frecuentes, devolver la respuesta localmente
      return of({ content: matchedFAQ });
    }

    // Si no hay coincidencia, consultar la API de OpenAI
    const requestBody = {
      model: 'gpt-4',
      messages: [
        {
          role: 'system',
          content: `
          Eres un asistente experto en el Monotributo en Argentina y un representante virtual de la app Tributo Simple.
          Tu tarea es ayudar a los usuarios a entender el sistema de Monotributo, realizar cálculos relacionados, guiar en la categorización,
          y responder preguntas frecuentes de manera clara, amigable y precisa.
          Actúa como un embajador de confianza para Tributo Simple, ayudando a simplificar la vida de los contribuyentes.
        `,
        },
        { role: 'user', content: message },
      ],
    };

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.apiKey}`,
      'Content-Type': 'application/json',
    });

    return this.httpClient.post(this.apiUrl, requestBody, { headers });
  }

  // Método para buscar una respuesta en las preguntas frecuentes
  private getFAQResponse(message: string): string | null {
    const normalizedMessage = this.normalizeText(message);

    for (const question of Object.keys(this.faqs)) {
      const normalizedQuestion = this.normalizeText(question);
      if (normalizedMessage.includes(normalizedQuestion)) {
        console.log(`Coincidencia encontrada: "${question}"`); // Coincidencia encontrada
        console.log(`Respuesta retornada: "${this.faqs[question]}"`); // Verifica la respuesta
        return this.faqs[question];
      }
    }

    console.log('No se encontró coincidencia en las preguntas frecuentes.'); // Sin coincidencia
    return null;
  }
  // Método para normalizar el texto (convierte a minúsculas y elimina tildes)
  private normalizeText(text: string): string {
    return text
      .toLowerCase()
      .normalize('NFD') // Separa caracteres diacríticos (tildes)
      .replace(/[\u0300-\u036f]/g, '') // Elimina los diacríticos
      .trim(); // Elimina espacios al inicio y final
  }
}
