import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-get-tax-info',
  templateUrl: './get-tax-info.component.html',
  styleUrls: ['./get-tax-info.component.scss'],
})
export class GetTaxInfoComponent {
  constructor(private modalController: ModalController, private navigationService: NavigationService) {}
  mobile: boolean;

  ngOnInit() {
    this.mobile = this.isMobile();
  }

  async dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  openCompletion() {
    window.open('https://serviciosweb.afip.gob.ar/genericos/formularios/pdf/interactivos/f460f.pdf', '_blank');
  }

  openTurnos() {
    window.open('https://serviciosweb.afip.gob.ar/Publico/Turnos/Turnos.aspx', '_blank');
  }

  goToTutorials() {
    this.dismiss()
    this.navigationService.navigateRoot('/tutorials');
  }

  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
