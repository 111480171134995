import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import localeEsAr from '@angular/common/locales/es-AR';
import { ChangeDetectorRef, Component } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MobileAccessibility } from '@awesome-cordova-plugins/mobile-accessibility/ngx';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { AlertController, IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { API_URL, TOKENMail, environment } from 'src/environments/environment';
import { register } from 'swiper/element/bundle';
import { Country } from './interfaces/country.interface';
import { AppLogService } from './services/appLog.service';
import { AuthService } from './services/auth.service';
import { BrowserService } from './services/native/browser.service';
import { KeyboardService } from './services/native/keyboard.service';
import { NavigationService } from './services/navigation.service';
import { StorageService } from './services/storage.service';
import { UpdateAppService } from './services/update-app.service';
import { UserDataService } from './services/user/user-data.service';

registerLocaleData(localeEsAr, 'es-AR');
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [IonRouterOutlet],
})
export class AppComponent {
  country$: Observable<Country>;
  servicioactivo: boolean;
  menuactivo: boolean;
  internet: boolean;
  fecha = new Date();
  mes = this.fecha.getMonth();
  dateButton: string;
  dateButtonOld: string;
  private intervalId: any;
  tieneInternet: boolean;
  info: any;
  constructor(
    private http: HttpClient,
    private browser: InAppBrowser,
    private platform: Platform,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private keyboardS: KeyboardService,
    private routerOutlet: IonRouterOutlet,
    private mobileAccessibility: MobileAccessibility,
    private browserService: BrowserService,
    private alertCtrl: AlertController,
    private updateAppSrv: UpdateAppService,
    private storageService: StorageService,
    private appLogService: AppLogService,
    private userDataService: UserDataService,
    private authService: AuthService,
    private navCtrl: NavController
  ) {
    this.initializeApp();

    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.routerOutlet.canGoBack()) {
        this.navigationService.back();
      }
    });
  }

  initializeApp() {
    this.tieneInternet = true;

    this.tieneInternet = false;

    this.menuactivo = false;
    this.servicioactivo = true;
    this.logDeviceInfo();
    this.retryConnection();
    const savedInfo = localStorage.getItem('info');
    if (savedInfo) {
      this.info = JSON.parse(savedInfo);
    }
    const today = localStorage.getItem('dateButton');
    if (today) {
      this.dateButtonOld = today;
    }
    this.platform.ready().then(() => {
      console.log('initializeApp');

      SplashScreen.hide();

      this.checkForUpdates();

      if (Capacitor.isNativePlatform()) {
        this.mobileAccessibility.getTextZoom().then((textZoom) => {
          if (textZoom > 130) {
            this.mobileAccessibility.setTextZoom(130);
          }
        });

        if (Capacitor.getPlatform() === 'ios') {
          this.routerOutlet.swipeGesture = false;
        }
      }

      this.keyboardS.initService();

      this.authService
        .getloggedIn()
        .pipe(
          filter((isLoggedIn) => isLoggedIn?.length > 0),
          switchMap(() => this.userDataService.refreshUserProfilePhoto())
        )
        .subscribe();
    });
    setTimeout(() => {
      this.menuactivo = true;
    }, 1500);
  }
  async checkForUpdates() {
    const attemptUpdateCheck = async () => {
      try {
        this.updateAppSrv.checkForUpdate().subscribe(
          async (update) => {
            let version = null;
            if (this.platform.is('ios')) {
              version = update.versions.ios;
            } else if (this.platform.is('android')) {
              version = update.versions.android;
            }

            if (version && environment.version !== version) {
              console.log(
                'update available',
                'update version: ' + version,
                'env version: ' + environment.version
              );

              const alert = await this.alertCtrl.create({
                header: `¡Nueva versión disponible! 🚀`,
                message: `Actualiza la app y descubrí las últimas mejoras, nuevas funciones y mejor experiencia.`,
                backdropDismiss: false,
                cssClass: 'custom-alert-style', // Usando la misma clase CSS
                buttons: [
                  {
                    text: 'Lo haré luego',
                    role: 'cancel', // Similar al rol "cancel" del primer ejemplo
                    handler: () => {
                      console.log('El usuario decidió no actualizar en este momento.');
                    },
                  },
                  {
                    text: 'Actualizar',
                    role: 'cerrar', // Similar al rol "cancel" del primer ejemplo

                    handler: () => {
                      const url = 'https://onelink.to/8dn4sf';
                      this.browserService.open(url, '_system');
                    },
                  },
                ],
              });

              await alert.present();
            }

            clearInterval(this.intervalId); // Detiene el intervalo una vez que se ha encontrado la actualización
            this.servicioactivo = true;
          },
          async (error) => {
            console.error('Error checking for updates', error);
            this.servicioactivo = false;
          }
        );
      } catch (error) {
        console.error('Error checking for updates', error);
        this.servicioactivo = false;
      }
    };

    // Realiza la verificación cada 2 segundos
    this.intervalId = setInterval(() => {
      if (!this.servicioactivo) {
        attemptUpdateCheck();
        this.retryConnection();
      }
    }, 2000);

    // Realiza la primera verificación inmediatamente
    await attemptUpdateCheck();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Limpia el intervalo cuando se destruye el servicio o componente
      location.reload();
    }
  }

  async logDeviceInfo() {
    const info = await Device.getInfo();
    console.log('logDeviceInfo:', info);
    this.info = info;
    localStorage.setItem('info', JSON.stringify(this.info));
  }

  async goToReload() {
    // const PROD_API_URL = 'https://backoffice.tributosimple.com/api/v1';

    // // if (String(API_URL) === String(PROD_API_URL)) {
    // //   if (!this.buttonDisabled) {
    // //     const requestBody = {
    // //       campaign: 'string',
    // //       channelId: 'tributosimple-whatsapp-5491166410871',
    // //       name: 'ts_backoffice_caido',
    // //       intentIdOrName: 'ts_backoffice_caido',
    // //       contacts: [
    // //         {
    // //           contactId: '+5491156915709',
    // //           variables: {
    // //             varName: 'some value',
    // //             numVar: '+5491156915709',
    // //           },
    // //           tags: {
    // //             tagName: true,
    // //             anotherTag: false,
    // //           },
    // //           webhookPayload: 'string',
    // //         },
    // //       ],
    // //     };
    // //     try {
    // //       const response = await this.http
    // //         .post('https://api.botmaker.com/v2.0/notifications', requestBody, {
    // //           headers: {
    // //             'access-token': `${token}`,
    // //           },
    // //         })
    // //         .toPromise();
    // //       console.log('Message sent successfully:', response);
    // //       location.reload();
    // //     } catch (error) {
    // //       console.error('Error sending message:', error);
    // //       location.reload();
    // //     }
    // //   }
    // //   location.reload();
    // //   this.buttonDisabled = true;
    // //   localStorage.setItem('isButtonUsed', 'true');
    // // }
    const PROD_API_URL = 'https://backoffice.tributosimple.com/api/v1';
    if (String(API_URL) === String(PROD_API_URL)) {
      const todayNow = new Date().toISOString().split('T')[0];
      console.error(todayNow, this.dateButtonOld);
      if (this.dateButtonOld !== todayNow) {
        const token = TOKENMail;
        this.info = localStorage.getItem('info');
        const request1 = {
          transactional_message_id: '5',
          identifiers: {
            id: 'asosa@tributosimple.com',
          },
          to: 'soporte@tributosimple.com',
          from: 'backoffice@email.tributosimple.com',
          subject: 'Conexion Rechazada',
          body: `Conexion rechazada no cumple con los estandares de tributo simple o el backend esta caido, por favor revisar el servidor para que los usuarios puedan ingresar. Información del dispositivo: ${JSON.stringify(
            this.info
          )}`,
        };
        const request2 = {
          transactional_message_id: '5',
          identifiers: {
            id: 'asosa@tributosimple.com',
          },
          to: 'asosa@tributosimple.com',
          from: 'backoffice@email.tributosimple.com',

          subject: 'Conexion Rechazada',
          body: `Conexion rechazada no cumple con los estandares de tributo simple o el backend esta caido, por favor revisar el servidor para que los usuarios puedan ingresar. this.información del dispositivo: ${JSON.stringify(
            this.info
          )}`,
        };
        const request3 = {
          transactional_message_id: '5',
          identifiers: {
            id: 'asosa@tributosimple.com',
          },
          to: 'backoffice@tributosimple.com',
          from: 'backoffice@email.tributosimple.com',
          subject: 'Conexion Rechazada',
          body: `Conexion rechazada no cumple con los estandares de tributo simple o el backend esta caido, por favor revisar el servidor para que los usuarios puedan ingresar. this.información del dispositivo: ${JSON.stringify(
            this.info
          )}`,
        };

        const url = 'https://api.customer.io/v1/send/email';
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', `Bearer ${token}`);

        this.http.post(url, request1, { headers }).subscribe(
          (response) => {
            console.log('Email sent successfully:', response);
            const today = new Date().toISOString().split('T')[0];
            localStorage.setItem('dateButton', today);
            location.reload();
          },
          (error) => {
            console.error('Error sending email:', error);
            location.reload();
          }
        );
        this.http.post(url, request2, { headers }).subscribe(
          (response) => {
            console.log('Email sent successfully:', response);
          },
          (error) => {
            console.error('Error sending email:', error);
          }
        );
        this.http.post(url, request3, { headers }).subscribe(
          (response) => {
            console.log('Email sent successfully:', response);
          },
          (error) => {
            console.error('Error sending email:', error);
          }
        );
      }
    }

    this.presentAlert('Se intentó la reconexión, pero no fue posible establecerla.');

    return;
  }

  async presentAlert(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Atención',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  recategorizationAvailable(res: any): boolean {
    const recaExtraDesde = new Date(res.recategorizacionFechaDesde).getTime();
    const recaExtraHasta = new Date(res.recategorizacionFechaHasta).getTime();
    const hoy = new Date(Date.now()).getTime();
    if (this.mes == 0 || this.mes == 6) {
      return true;
    } else if (hoy >= recaExtraDesde && hoy <= recaExtraHasta) {
      return true;
    } else {
      return false;
    }
  }

  retryConnection() {
    // Verifica la conexión de Internet o realiza alguna acción
    if (navigator.onLine) {
      this.internet = true;
    } else {
      this.internet = false;
    }
  }

  reload() {
    location.reload();
  }
}
