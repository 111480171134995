import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IContact } from '../interfaces/contact.interfaces';
import { ResponseI } from '../interfaces/response.interface';
import { CurrentCountryService } from './current-country.service';

type Params = {
  tipoCliente?: string;
  tipoFactura?: string;
  contactoId?: number;

};

@Injectable({
  providedIn: 'root',
})
export class ApiContactoUsuarioTSService {
  get API_URL() {
    return this.currentCountryService.getApiUrl();
  }

  constructor(private http: HttpClient, private currentCountryService: CurrentCountryService) {}

  getAll(params?: Params) {
    return this.http
      .get<ResponseI<IContact[]>>(`${this.API_URL}/contactoUsuarioTS/readAll`, {
        params: this.parseParams(params),
      })
      .pipe(map((res) => res?.data || []));
  }

  getContact(contactId) {
    return this.http
      .get<ResponseI<[]>>(`${this.API_URL}/contactoUsuarioTS/${contactId}/read`, {
        params: this.parseParams(contactId),
      })
      .pipe(map((res) => res?.data || []));
  }

  getContactoNuevo(cuit) {
    return this.http
      .get<ResponseI<[]>>(`https://api-kyc.tributosimple.com/datos-persona/complete/${cuit}`, {
        params: this.parseParams(cuit),
      })
      .pipe(map((res) => res?.data || []));
  }

  search(search: string, params?: Params) {
    return this.http
      .get<ResponseI<IContact[]>>(`${this.API_URL}/contactoUsuarioTS/search/${search}`, {
        params: this.parseParams(params),
      })
      .pipe(map((res) => res?.data || []));
  }

  createEndConsumer(data: any) {
    return this.http
      .post<ResponseI<IContact>>(`${this.API_URL}/contactoUsuarioTS/create-consumidor-final`, data)
      .pipe(map((res) => res?.data));
  }

  private parseParams(params?: Params) {
    if (!params) {
      return {};
    }

    const { tipoCliente, tipoFactura } = params;

    let _params = new HttpParams();

    tipoCliente && (_params = _params.set('tipo', tipoCliente));
    tipoFactura && (_params = _params.set('tipoFactura', tipoFactura));

    return _params;
  }
}
